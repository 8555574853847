.App-header {
    width: 100%;
    background-color: #f5f5f5; /* Example background color */
    padding: 10px 0; /* Add some padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.App-header h1 {
    margin: 0; /* Removes default margin */
    font-size: 24px; /* Example font size */
    font-family: Calibri;
    /* Add other font styles as needed */
}
@media print {
    .App-header {
        display: none;
    }
}