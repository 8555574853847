/* Global styles */
.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.vehicle-frame, .print-version .vehicle-frame-print {
  width: 310px; /* Fixed width */
  height: 189px; /* Fixed height */
  border: 2px dashed black;
  position: relative;
  transform-origin: center;
  justify-self: center;
  margin: 0 auto;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  background-color: white; /* Ensure background is white */
}


@media screen {
  .print-version, .qr-code-print {
    display: none;
  }

  .title, .shortcode, .license-plate, .vin {
    position: absolute;
  }

  .title {
    top: 13px; /* 4.2mm */
    left: 45px; /* Adjusted left */
    font-size: 17px;
  }

  .shortcode {
    top: 45px; /* 14mm */
    left: 20px; /* Adjusted left */
    font-size: 34px;
    font-weight: bold;
  }

  .license-plate {
    top: 113px; /* 30mm */
    left: 8px; /* Adjusted left */
    font-size: 12px;
  }

  .vin {
    top: 136px; /* 36mm */
    left: 8px; /* Adjusted left */
    font-size: 12px;
  }

  .qr-code {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 120px;
    height: 120px;
    overflow: hidden;
  }

  .qr-code svg {
    width: 100%;
    height: 100%;
  }

  .bold-text {
    font-weight: bold;
  }

  .form-container {
    width: 600px; /* Specific width */
    margin-left: 20px; /* Left alignment */
  }

}

@media print {
  /* Hide screen-only elements */
  .form-container, .print-button, .vehicle-frame, .container, .App-header, .App-header h1 {
    display: none;
  }
  .App-header {
    display: none;
  }

  .App {
    display: none;
  }
  .bold-text {
    font-weight: bold;
  }

  /* Show print version */
  .print-version {
    display: block;
  }

  .print-version .vehicle-frame-print {
    width: calc(50% - 20px); /* Half the width minus some margin */
    display: inline-flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 5px; /* Adjust margin as needed */
  }

  .title, .shortcode, .license-plate, .vin {
    position: absolute;
  }

  .title {
    top: 13px; /* 4.2mm */
    left: 63px; /* 12.3mm */
    font-size: 17px;
  }

  .shortcode {
    top: 45px; /* 14mm */
    left: 38px; /* 12.3mm */
    font-size: 34px;
    font-weight: bold;
  }

  .license-plate {
    top: 113px; /* 30mm */
    left: 12px; /* 5.4mm */
    font-size: 12px;
  }

  .vin {
    top: 136px; /* 36mm */
    left: 12px; /* 4.5mm */
    font-size: 12px;
  }

  .qr-code {
    position: absolute;
    top: 15px;  /* Adjust as needed */
    right: 15px;  /* Adjust as needed */
    transform: scale(0.90); /* Downscale by x% */
    /* This will position QR codes in the top-right corner of each vehicle-frame */
  }

  .print-page-break {
    page-break-before: always; /* Ensure break is before this element */
    clear: both;
    display: block;
    height: 1px;
    visibility: hidden;
  }
}
